import React, { Fragment, useEffect, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/gptSearch.scss";
import { CiSearch } from "react-icons/ci";
import { AiFillAudio, AiFillDislike, AiFillLike } from "react-icons/ai";
import axios from "axios";
import { LuBookmark, LuLoader } from "react-icons/lu";
import { RiVoiceprintFill } from "react-icons/ri";
import GptSidebar from "./gptSidebar";
import { TiArrowUp } from "react-icons/ti";
import { MdAdd, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useTour } from "@reactour/tour";
import {
  Gto,
  OIR,
  courses,
  currentAffair,
  hideVideo,
  interview,
  lamp1,
  lamp2,
  lamp3,
  linkSearch,
  loaderGif,
  logo_mobile,
  olq,
  ppdtIcon,
  psychology,
  searchBook,
  spokenEnglish,
  why1,
} from "../../assets/images";
import userService from "../../services/userService";
import bookService from "../../services/bookService";
import { toast } from "react-toastify";
import { BsPlayCircle } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userEndPoints } from "../../config";
import Loader from "../reusable/Loader";
import { useAuth } from "../../context/userContext";
import moment from "moment";

import CustomHelmet from "../reusable/Helmet";
import WhatCanDo from "./WhatCanDo";
import GptBooks from "./gptBooks";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const GptSearch = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const localToken = localStorage.getItem("ssb-token")
      ? JSON.parse(localStorage.getItem("ssb-token"))
      : {};

    const data = {
      success: true,
      token: token,
    };
    if (!localToken) {
      localStorage.setItem("ssb-token", data);
    }
  }, [location.search]);

  const [searchValue, setSearchValue] = useState("");
  const [placeHolderInput, setPlaceholderInput] = useState(
    "Search anything related to ssb"
  );
  const [activeTab, setActiveTab] = useState("question");
  const [activeinput, setActiveInput] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localHistory, setlocalHistory] = useState([]);
  const [mediaOpen, setMediaOpen] = useState(false);
  // const [mediaFile, setMediaFile] = useState([]);
  const [ppdtImage, setPpdtImage] = useState();
  const [imageQuery, setImageQuery] = useState("");
  const [ppdtTimer, setPpdtTimer] = useState(30);
  const [pdfFile, setPdfFile] = useState("");

  const [srchToday, setSrchToday] = useState(true);

  // ALL BOOK SAVE STATE STARTS

  useEffect(() => {
    const existingLocalHistory = localStorage.getItem("userHistory")
      ? JSON.parse(localStorage.getItem("userHistory"))
      : [];
    setlocalHistory(existingLocalHistory);
  }, []);

  const todatDate = new Date();
  const currentDate = moment(todatDate).format("YYYY-MM-DD");

  const handleVoice = () => {
    setIsListening(true);
    const recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.maxResults = 10;
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSearchValue(transcript);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
  };

  const [disableVideo, setDisableVideo] = useState(
    () => JSON.parse(localStorage.getItem("videoSrch")) ?? true
  );

  const handleDisableVideo = () => {
    setDisableVideo((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("videoSrch", JSON.stringify(disableVideo));
  }, [disableVideo]);

  //  POST DATA TO API START
  const handleAskQuestion = () => {
    setPlaceholderInput("Ask anything related to ssb");
    setActiveInput(true);
    setMediaOpen(false);
    setActiveTab("question");
  };

  const handleSearchResult = async (query) => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await userService.userChat(searchValue);
      if (!disableVideo) {
        var vdResult = await bookService.getVideoSearch(searchValue);
      }

      if (result) {
        const videoId = vdResult?.data?.video?.url;
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                  url: videoId,
                  videoTitle: vdResult?.data?.title,
                }
              : entry
          )
        );
        let updatedList = [...localHistory];
        const existingEntry = updatedList.find(
          (item) => item.dayData === currentDate
        );

        if (existingEntry) {
          existingEntry.searchData.push({
            question: searchValue,
            answer: result?.data?.response,
            url: videoId,
            videoTitle: vdResult?.data?.title,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
                url: videoId,
                videoTitle: vdResult?.data?.title,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  
  //  POST DATA TO API ENDS

  const handleMediaOpen = () => {
    setMediaOpen(!mediaOpen);
  };

  // OPEN MEDIA SELECT BAR ENDS

  useEffect(() => {
    if (ppdtImage && ppdtTimer > 0) {
      setTimeout(() => {
        setPpdtTimer(ppdtTimer - 1);
      }, 1000);
    }
  });

  const handlePsychologyTest = () => {
    navigate("/psychology-test-attempt");
  };

  // IMAGE GENRATE STARTS
  const handleImageGenrate = async (query) => {
    setIsLoading(true);
    setActiveInput(true);
    setActiveTab(query);
    setMediaOpen(false);
    setSrchToday(true);
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    setPlaceholderInput("Please wait for sometime we are generating the data");
    try {
      const apiResponseData = await userService.generateTatImage(query);
      if (apiResponseData) {
        setImageQuery(query);
        setPpdtImage(apiResponseData?.data?.data);
        setIsLoading(false);
        setMediaOpen(false);
        setPlaceholderInput("Ask anything related to ssb");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // IMAGE GENRATE ENDS

  // OIR MCQ'S TEST GENRATE START
  const handleOIR = () => {
    setPlaceholderInput("Write the topic for generate MCQ");
    setActiveInput(true);
    setActiveTab("oir");
    setMediaOpen(false);
  };
  // OIR MCQ'S TEST GENRATE ENDS

  // Link TEST GENRATE START
  const handleLinkSearch = () => {
    setPlaceholderInput("Write the link to summerise the data");
    setActiveInput(true);
    setActiveTab("link");
    setMediaOpen(false);
  };

  const handleLinkGenrate = async () => {
    setIsLoading(true);
    setSearchValue("");
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.linkSummarize, {
        input_text: searchValue,
      });

      if (result?.data?.response !== "No input provided") {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );

        const currentDate = moment().format("YYYY-MM-DD");
        let updatedList = [...questionList];

        if (
          updatedList.length > 0 &&
          updatedList[updatedList.length - 1].dayData === currentDate
        ) {
          updatedList[updatedList.length - 1].searchData.push({
            question: searchValue,
            answer: result?.data?.response,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
      } else {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: "Sorry i have no provided data for that",
                }
              : entry
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setMediaOpen(false);
    }
  };
  //Link TEST GENRATE ENDS

  // HANDLE PDF UPLOAD START
  const handlePdfSearch = () => {
    setActiveInput(true);
    setActiveTab("pdf");
    setMediaOpen(false);
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
    }
  };

  const handlePdfUpload = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", pdfFile);

      const result = await axios.post(userEndPoints.pdfUpload, formData);

      if (result) {
        setOutputData([
          ...outputData,
          { answer: result?.data?.response, question: searchValue },
        ]);
        setIsLoading(false);
        setSearchValue("");
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  const handlePdfSearchQuestion = async () => {
    setIsLoading(true);
    setSearchValue("");
    setMediaOpen(false);
    setSrchToday(true);
    const newEntry = { question: searchValue, answer: "Loading..." };
    setOutputData((prevData) => [...prevData, newEntry]);
    try {
      const result = await axios.post(userEndPoints.askQuestion, {
        input_text: searchValue,
      });

      if (result) {
        setOutputData((prevData) =>
          prevData.map((entry, index) =>
            index === prevData.length - 1
              ? {
                  ...entry,
                  answer: result?.data?.response.replace(/Alpha Bot:\s*/g, ""),
                }
              : entry
          )
        );
        const currentDate = moment().format("YYYY-MM-DD");
        let updatedList = [...questionList];

        if (
          updatedList.length > 0 &&
          updatedList[updatedList.length - 1].dayData === currentDate
        ) {
          updatedList[updatedList.length - 1].searchData.push({
            question: searchValue,
            answer: result?.data?.response,
          });
        } else {
          updatedList.push({
            dayData: currentDate,
            searchData: [
              {
                question: searchValue,
                answer: result?.data?.response,
              },
            ],
          });
        }
        setQuestionList(updatedList);
        setlocalHistory(updatedList);
        localStorage.setItem("userHistory", JSON.stringify(updatedList));
        setIsLoading(false);
        setPlaceholderInput("Search anything from provided pdf");
        setActiveTab("pdf_srch");
      }
    } catch (error) {
      setIsLoading(false);
      setMediaOpen(false);
    }
  };

  // HANDLE PDF UPLOAD ENDS

  // HANDLE VIDEO SEARCH STARTS

  // const getYouTubeVideoId = (url) => {
  //   const regex = /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
  //   const match = url.match(regex);
  //   return match ? match[1] : null;
  // };

  // const fetchVideo = async () => {
  //   setIsLoading(true);
  //   setSearchValue("");
  //   setMediaOpen(false);
  //   const newEntry = {
  //     question: searchValue,
  //     url: null,
  //     videoTitle: "",
  //   };
  //   setOutputData((prevData) => [...prevData, newEntry]);
  //   try {
  //     const result = await bookService.getVideoSearch(searchValue);
  //     if (result) {
  //       // const videoId = getYouTubeVideoId(result?.data?.video?.url);
  //       const videoId = result?.data?.video?.url;

  //       setIsLoading(false);
  //       setPlaceholderInput("Search video");
  //       setActiveTab("video");
  //       setOutputData((prevData) =>
  //         prevData.map((entry, index) =>
  //           index === prevData.length - 1
  //             ? {
  //                 ...entry,
  //                 url: videoId,
  //                 answer: `Here is your video for your ${searchValue} search`,
  //                 videoTitle: result?.data?.title,
  //               }
  //             : entry
  //         )
  //       );

  //       const currentDate = moment().format("YYYY-MM-DD");
  //       let updatedList = [...questionList];
  //       if (
  //         updatedList.length > 0 &&
  //         updatedList[updatedList.length - 1].dayData === currentDate
  //       ) {
  //         updatedList[updatedList.length - 1].searchData.push({
  //           question: searchValue,
  //           answer: `Here is your video for your ${searchValue} search`,

  //           url: videoId,
  //         });
  //       } else {
  //         updatedList.push({
  //           dayData: currentDate,
  //           searchData: [
  //             {
  //               question: searchValue,
  //               answer: `Here is your video for your ${searchValue} search`,
  //               url: videoId,
  //             },
  //           ],
  //         });
  //       }
  //       setQuestionList(updatedList);
  //       setlocalHistory(updatedList);
  //       localStorage.setItem("userHistory", JSON.stringify(updatedList));
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     setMediaOpen(false);
  //   }
  // };

  const handleVideoView = (url) => {
    // const videoId = getYouTubeVideoId(url);
    if (url) {
      navigate(`/video?streamId=${url}`);
    }
  };

  // HANDLE VIDEO SEARCH ENDS

  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS
  // INTERVIEW ATTEND FUNCTION API'S CALL STARTS

  const handleAttendInterview = () => {
    if (auth?.user?._id) {
      navigate("/interview");
    } else {
      navigate("/login", { state: "/interview" });
    }
  };

  const handleNavigateCourses = () => {
    window.open("https://testing.alpharegiment.in/ssb-courses");
  };

  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS
  // INTERVIEW ATTEND FUNCTION API'S CALL ENDS

  // test start timer

  // STEPS FOR GIVING OVERVIEW
  const { currentStep, isOpen } = useTour();

  useEffect(() => {
    if (currentStep === 0 && isOpen) {
      setMediaOpen(true);
    }
  }, [currentStep, isOpen]);

  // SCROLL HER PAGE AFTER EVERY SEARCH STARTS
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current && outputData) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [outputData]);

  // SCROLL HER PAGE AFTER EVERY SEARCH ENDS

  // CHECK USER TODAY SEARCH HISTORY STARTS
  const savedList = localStorage.getItem("userHistory");

  useEffect(() => {
    if (savedList) {
      const data = JSON.parse(savedList);
      if (data === null) {
        setSrchToday(true);
      } else {
        const today = new Date().toISOString().split("T")[0];
        const checkSearchToday = data.some((item) => item.dayData === today);
        const searchHistory = data.find(
          (item) => item.dayData === today
        )?.searchData;
        if (searchHistory) {
          setOutputData(searchHistory);
        }
        setSrchToday(checkSearchToday);
      }
      setQuestionList(data);
    }
  }, [savedList]);

  const handleShowHistory = (date) => {
    const extractedData = questionList
      ?.filter((ele) => ele?.dayData === date)
      .map((item) => item.searchData)
      .flat();
    setOutputData(extractedData);
  };

  // CHECK USER TODAY SEARCH HISTORY ENDS

  // SPOKEN ENGLISH STARTS

  const handleSpokenEnglish = () => {
    setActiveTab("spokenEnglish");
    setMediaOpen(false);
    setSrchToday(true);
    setOutputData((prevData) => [
      ...prevData,
      {
        question:
          "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",
        spokenType: [
          {
            type: "Watch Videos for Spoken English Basics",
            query: "",
          },
          {
            type: "Learn Spoken English with AI",
            query: "/spoken-english",
          },
          {
            type: "Group Discussion with Other Aspirants",
            query: "/spoken-english",
          },
        ],
      },
    ]);
    let updatedList = [...localHistory];
    const existingEntry = updatedList.find(
      (item) => item.dayData === currentDate
    );
    if (existingEntry) {
      existingEntry.searchData.push({
        question:
          "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

        spokenType: [
          {
            type: "Watch Videos for Spoken English Basics",
            query: "",
          },
          {
            type: "Learn Spoken English with AI",
            query: "/spoken-english",
          },
          {
            type: "Group Discussion with Other Aspirants",
            query: "/spoken-english",
          },
        ],
      });
    } else {
      updatedList.push({
        dayData: currentDate,
        searchData: [
          {
            question:
              "Everyone learns differently, so here you can improve your spoken english in various ways. Choose the method that works best for you.",

            spokenType: [
              {
                type: "Watch Videos for Spoken English Basics",
                query: "",
              },
              {
                type: "Learn Spoken English with AI",
                query: "/spoken-english",
              },
              {
                type: "Group Discussion with Other Aspirants",
                query: "/spoken-english",
              },
            ],
          },
        ],
      });
    }
    setQuestionList(updatedList);
    setlocalHistory(updatedList);
    localStorage.setItem("userHistory", JSON.stringify(updatedList));
  };

  // HANDLE SPOKEN QUERY
  const handleSpokenQuery = (query) => {
    navigate(query);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchValue !== "") {
      handleSearch();
    }
  };

  // HANDLE FUNCTION FOR ANALYSING USER INPUT
  const keywords = {
    srt: ["srt", "situation", "situations", "Give SRT", "Practice srt"],
    link: ["http", "https", "www"],
    ppdt: ["PP&DT", "PP & DT", "ppdt", "PPDT"],
    tat: ["tat", "TAT"],
    wat: ["word", "wat", "WAT"],
    interview: ["interview"],
    pdfSearch: ["pdf", "pdfSearch"],
    olq: ["olq", "OLQ", "olq analysis"],
    oir: ["Give OIR", "OIr test", "oir"],
    spoken: ["Spoken English", "Spoken", "english"],
    video: ["video", "learn by video"],
    courses: [
      "Coaching",
      "SSB Institute",
      "Psychology dossier checked by ex-psychologist ",
      "Mock Psychology",
      "Mock Interview",
      "Mock SSB",
    ],
  };

  const [message, setMessage] = useState("");
  const [suggestionTab, setSuggestionTab] = useState("ask");

  const handleInputChange = (e) => {
    const valueData = e.target.value;
    setSearchValue(valueData);
    analyzeInput(valueData.trim());
  };

  const analyzeInput = (input) => {
    const lowerCaseInput = String(input).toLowerCase();
    let matchedKeyword = null;
    Object.keys(keywords).forEach((keyword) => {
      if (
        keywords[keyword].some((pattern) =>
          lowerCaseInput.includes(pattern.toLowerCase())
        )
      ) {
        matchedKeyword = keyword;
      }
    });
    if (matchedKeyword === "srt") {
      setMessage(`Would you like to get SRT?`);
      setSuggestionTab("srt");
    }
    if (matchedKeyword === "wat") {
      setMessage(`Would you like to get WAT?`);
    }
    if (matchedKeyword === "ppdt") {
      setMessage(`Would you like to get  PPDT / PP&DT?`);
      setSuggestionTab("PPDT");
    }
    if (matchedKeyword === "tat") {
      setMessage(`Would you like to get TAT ?`);
      setSuggestionTab("TAT");
    }
    if (matchedKeyword === "oir") {
      setMessage(`Would you like to get OIR ?`);
      setSuggestionTab("oir");
    }
    if (matchedKeyword === "pdfSearch") {
      setMessage(`Would you like a summary, MCQs, or specific information from the PDF?
`);
      setSuggestionTab("pdf");
    }
    if (matchedKeyword === "olq") {
      setMessage(
        `Do you mean you want to get your OLQ analysis through a simple psychometric test?`
      );
    }
    if (matchedKeyword === "link") {
      setMessage(
        `Would you like a summary, MCQs, or specific information from this link?`
      );
      setSuggestionTab("link");
    }

    if (matchedKeyword === "interview") {
      setMessage(`Want to give an interview`);
      setSuggestionTab("interview");
    }
    if (matchedKeyword === "courses") {
      setMessage(`Want to browse ssb courses`);
      setSuggestionTab("courses");
    }

    if (matchedKeyword === "video") {
      setMessage(`Would you like your questions answered in a video?
`);
      setSuggestionTab("video");
    }

    if (matchedKeyword === "spoken") {
      setMessage(`Do you mean you want to practice spoken English with AI?
`);
      setSuggestionTab("spokenEnglish");
    }

    return matchedKeyword;
  };

  // HANDLE SEARCH VIW POPUP MESSAGE

  const handleSearchPop = () => {
    setMessage("");
    if (suggestionTab === "question") {
      handleSearchResult();
    }
    if (suggestionTab === "oir") {
      toast.warning("We Launch OIR test feature soon");
    }
    if (suggestionTab === "link") {
      handleLinkGenrate();
    }
    if (suggestionTab === "pdf") {
      handlePdfUpload();
    }

    if (suggestionTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }

    if (suggestionTab === "courses") {
      handleNavigateCourses();
    }
  };

  const handleSearch = () => {
    setMessage("");
    if (activeTab === "question") {
      handleSearchResult();
    }
    if (activeTab === "oir") {
      toast.warning("We Launch OIR test feature soon");
    }
    if (activeTab === "link") {
      handleLinkGenrate();
    }
    if (activeTab === "pdf") {
      handlePdfUpload();
    }

    if (activeTab === "pdf_srch") {
      handlePdfSearchQuestion();
    }
  };

  // SPOKEN ENGLISH ENDS

  const [authLoading, setAuthLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const fetchBookList = async () => {
    try {
      setAuthLoading(true);
      const res = await bookService.myAllBookList();
      if (res) {
        setAuthLoading(false);
        setBookList(res?.data?.books);
      }
    } catch (error) {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?._id) {
      fetchBookList();
    }
  }, [auth?.user?._id]);
  const [saveValue, setSaveValue] = useState();
  const handleSave = (idx) => {
    setSaveValue(idx);
  };

  return authLoading ? (
    <Loader />
  ) : (
    <Fragment>
      <CustomHelmet
        title="SSB-GPT: Your SSB Interview Self Preparation Tool"
        description="Prepare effectively for your SSB/AFSB/NSB/ interview with SSB-GPT, a free self-preparation tool."
      />
      <GptLayout>
        <section className="gpt_layout_wrapper">
          <GptSidebar setOutputData={setOutputData} />
          <section className="gpt_Search_box">
            <div className="input_box">
              <div className={`srch_output custonScroll`} ref={scrollRef}>
                {!srchToday && (
                  <div className="wlcm_msg">
                    <div className="title">
                      <h2>SSB-GPT</h2>
                    </div>
                    <div className="overview_box">
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp1} alt="lamp1" />
                          <p>Examples</p>
                        </div>
                        <ul>
                          <li>
                            <p>
                              Explain the complete 5-day process of the SSB
                              interview.
                            </p>
                          </li>
                          <li>
                            <p>Analyse My TAT Story</p>
                          </li>
                          <li>
                            <p>Analyse My OLQs</p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp2} alt="lamp2" />
                          <p>Capabilities</p>
                        </div>
                        <ul>
                          <li>
                            <p>SSB-GPT can analyse your psychology dossier</p>
                          </li>
                          <li>
                            <p>SSB-GPT can conduct your interview</p>
                          </li>
                          <li>
                            <p>
                              Aspirants can also practice GTO in 3D Simulation.
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="over_view_msg">
                        <div className="sub_title">
                          <img src={lamp3} alt="lamp3" />
                          <p>Quality</p>
                        </div>
                        <ul>
                          <li>
                            <p>Trained by SSB experts.</p>
                          </li>
                          <li>
                            <p>
                              Practice unlimited sets of TAT, WAT, SRT, etc.
                            </p>
                          </li>
                          <li>
                            <p>Helps improve English communication as well.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* SEARCH HISORTY AND ASK QUESTION BOX  */}
                {outputData?.length > 0 &&
                  outputData?.map((ele, idx) => (
                    <>
                      {ele?.question && <h5>{ele?.question}</h5>}
                      {ele?.srtList?.length > 0 && (
                        <h5>
                          {ele?.srtList?.map((itm, id) => (
                            <span key={id}>
                              {id + 1}. {itm}
                            </span>
                          ))}
                        </h5>
                      )}
                      {(ele?.answer || ele?.spokenType?.length > 0) && (
                        <div className="answer_box">
                          <button
                            className="stop_video"
                            title="Disable Video suggestion"
                            onClick={() => handleDisableVideo()}
                          >
                            <img src={hideVideo} alt="hideVideo" />
                          </button>
                          <div>
                            <div className="output_box">
                              {ele?.spokenType?.length > 0 &&
                                ele?.spokenType?.map((itm, id) => (
                                  <button
                                    key={id}
                                    onClick={() =>
                                      handleSpokenQuery(itm?.query)
                                    }
                                  >
                                    {itm?.type}
                                  </button>
                                ))}

                              <div className="video_data">
                                <div className="video_content" key={idx}>
                                  {ele?.title !== "" && <p>{ele?.title}</p>}
                                  {ele?.url && ele?.url !== null && (
                                    <div className="thumbnail-container">
                                      <iframe
                                        className="thumbnail"
                                        src={ele?.url}
                                        title={ele?.url}
                                        frameBorder="0"
                                        allowFullScreen
                                      />
                                      <div
                                        className="overlay"
                                        onClick={() =>
                                          handleVideoView(ele?.url)
                                        }
                                      >
                                        <BsPlayCircle />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {ele?.answer !== "" ? (
                                <>
                                  {ele?.answer?.Title && (
                                    <p>
                                      <strong>Title :</strong>
                                      <span>{ele?.answer?.Title}</span>
                                    </p>
                                  )}
                                  {ele?.answer?.Content && (
                                    <p>
                                      <strong>Content :</strong>
                                      <span>{ele?.answer?.Content}</span>
                                    </p>
                                  )}
                                  {ele?.answer?.Summary && (
                                    <p>
                                      <strong>Summary :</strong>
                                      <span>{ele?.answer?.Summary}</span>
                                    </p>
                                  )}
                                  {!ele?.answer?.Title &&
                                    !ele?.answer?.Content &&
                                    !ele?.answer?.Summary && (
                                      <p>
                                        <span>
                                          {ele?.answer?.replace(
                                            /Alpha Bot:\s*/g,
                                            ""
                                          )}
                                        </span>
                                      </p>
                                    )}
                                </>
                              ) : (
                                <div className="video_data">
                                  <div className="video_content" key={idx}>
                                    {ele?.title !== "" && <p>{ele?.title}</p>}
                                    {ele?.url === "Loading..." ? (
                                      <p>{ele?.url}</p>
                                    ) : (
                                      <div
                                        className="thumbnail"
                                        onClick={() =>
                                          handleVideoView(ele?.url)
                                        }
                                      >
                                        <img
                                          src={logo_mobile}
                                          alt="thumbnail"
                                        />
                                        <span>
                                          <BsPlayCircle />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="action_btn">
                              <span onClick={() => handleSave(idx)}>
                                <LuBookmark />
                                Save
                              </span>
                              <span>
                                <AiFillDislike />
                              </span>
                              <span>
                                <AiFillLike />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                {/* SEARCH HISTORY AND ASK QUESTION BOX ENDS */}

                <div className="tour_msg"></div>
                {/* HANLDE TAT LOGICS STARTS  */}

                {activeTab === "TAT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 5-6 min. <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}

                {activeTab === "PPDT" && (
                  <div className="tat_test_msg">
                    <p>
                      Your test is preparing. It will take upto 40 - 50 sec.{" "}
                      <br />
                      Do not refresh the page in case you may loss data
                    </p>
                  </div>
                )}

                <div className="mock_text_wrapper">
                  {imageQuery === "PPDT" && (
                    <div className="test_start">
                      <div className="test_start">
                        {ppdtTimer > 0 ? (
                          <div className="question_list">
                            <img src={ppdtImage} alt={ppdtImage} />
                          </div>
                        ) : (
                          <textarea rows="5" placeholder="Start writing..." />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {isLoading && activeTab === "video" && (
                  <div className="search_loader">
                    <img src={loaderGif} alt="loader" />
                  </div>
                )}
              </div>

              <div className="srch_input">
                {message !== "" && (
                  <div className="suggestion_box">
                    <p>{message}</p>
                    <div className="box">
                      <button onClick={() => setMessage("")}>No</button>
                      <button onClick={handleSearchPop}>Yes</button>
                    </div>
                  </div>
                )}
                <div>
                  {activeTab === "pdf" && (
                    <div
                      className="upload_box"
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                    >
                      <input
                        type="file"
                        accept="*/.pdf"
                        onFocus={() => {
                          setMediaOpen(false);
                        }}
                        onChange={(e) => handleMediaChange(e)}
                        placeholder={placeHolderInput}
                      />
                      <button
                        className="media_btn"
                        onClick={() => handleSearch()}
                      >
                        {isLoading ? <LuLoader /> : <TiArrowUp />}
                      </button>
                    </div>
                  )}

                  {activeTab !== "pdf" && (
                    // && activeTab !== "srt"
                    <input
                      type="text"
                      value={searchValue}
                      style={{ border: activeinput ? "1px solid #fff" : "" }}
                      onChange={(e) => handleInputChange(e)}
                      onFocus={() => {
                        setMediaOpen(false);
                      }}
                      onKeyDown={handleKeyDown}
                      placeholder={placeHolderInput}
                    />
                  )}

                  <div className="media_wrapper">
                    <div
                      className={`media_drop_up ${
                        mediaOpen ? "media_drop_up_on" : ""
                      }`}
                    >
                      <ul>
                        <li>
                          <button
                            className={`media_select ask_que ${
                              activeTab === "question" ? "active" : ""
                            }`}
                            onClick={() => handleAskQuestion()}
                          >
                            <img src={why1} alt="why1" />
                            <p>Ask Question</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que1 ${
                              activeTab === "oir" ? "active" : ""
                            }`}
                            onClick={() => handleOIR()}
                          >
                            <img src={OIR} alt="OIR" />
                            <p>OIR Test</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que2 ${
                              activeTab === "link" ? "active" : ""
                            }`}
                            onClick={() => handleLinkSearch()}
                          >
                            <img src={linkSearch} alt="linkSearch" />
                            <p>Link Search</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que3 ${
                              activeTab === "TAT" ? "active" : ""
                            }`}
                            onClick={() => handlePsychologyTest()}
                          >
                            <img src={psychology} alt="psychology" />
                            <p>Psychology Test</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que4 ${
                              activeTab === "PPDT" ? "active" : ""
                            }`}
                            onClick={() => handleImageGenrate("PPDT")}
                          >
                            <img src={ppdtIcon} alt="ppdtIcon" />
                            <p>PPDT</p>
                          </button>
                        </li>

                        <li>
                          <button
                            className={`media_select ask_que6 ${
                              activeTab === "pdf" ? "active" : ""
                            }`}
                            onClick={() => handlePdfSearch()}
                          >
                            <img src={searchBook} alt="searchBook" />
                            <p>Book Search</p>
                          </button>
                        </li>
                        {/* <li>
                          <button
                            className="media_select ask_que7"
                            onClick={() => handleMediaOpen()}
                          >
                            <img src={wat1} alt="wat1" />
                            <p>WAT</p>
                          </button>
                        </li> */}
                        {/* <li>
                          <button
                            className="media_select ask_que8"
                            onClick={() => handleVideoSearch()}
                          >
                            <img src={musicPlayer} alt="wat1" />
                            <p>Video Search</p>
                          </button>
                        </li> */}
                        <li>
                          <button className="media_select ask_que9">
                            <img src={Gto} alt="wat1" />
                            <p>GTO</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que10"
                            onClick={() => handleNavigateCourses()}
                          >
                            <img src={courses} alt="wat1" />
                            <p>Courses</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que11"
                            // onClick={() => handleVideoSearch()}
                          >
                            <img src={olq} alt="wat1" />
                            <p>OLQ Analysis</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className="media_select ask_que12"
                            onClick={() => handleAttendInterview()}
                          >
                            <img src={interview} alt="wat1" />
                            <p>Mock Interview</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que13 ${
                              activeTab === "currentAffair" ? "active" : ""
                            }`}
                            // onClick={() => handleSpokenEnglish()}
                          >
                            <img src={currentAffair} alt="currentAffair" />
                            <p>Current Affairs</p>
                          </button>
                        </li>
                        <li>
                          <button
                            className={`media_select ask_que14`}
                            onClick={() => handleSpokenEnglish()}
                          >
                            <img src={spokenEnglish} alt="spokenEnglish" />
                            <p>Spoken English</p>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <button
                      className="media_btn"
                      onClick={() => handleMediaOpen()}
                    >
                      {mediaOpen ? <MdOutlineKeyboardArrowUp /> : <MdAdd />}
                    </button>
                  </div>

                  {searchValue === "" ? (
                    <button className="search" onClick={() => handleVoice()}>
                      {isListening ? <RiVoiceprintFill /> : <AiFillAudio />}
                    </button>
                  ) : (
                    <button className="search" onClick={() => handleSearch()}>
                      {isLoading ? <LuLoader /> : <CiSearch />}
                    </button>
                  )}
                </div>

                <p className="advice_txt">
                  SSB-GPT can make mistakes. We suggest seeking advice from
                  experts. Read our <Link to="/term-of-use">Terms</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </section>
          <GptBooks
            bookList={bookList}
            fetchBookList={fetchBookList}
            handleSave={saveValue}
            outputData={outputData}
            // activeTab={activeTab}
            questionList={questionList}
            handleShowHistory={handleShowHistory}
          />
        </section>

        <WhatCanDo />
      </GptLayout>
    </Fragment>
  );
};

export default GptSearch;
