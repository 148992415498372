import React, { Fragment, useRef, useState } from "react";
import GptLayout from "./gptLayout";
import "../../styles/interview.scss";
import { useAuth } from "../../context/userContext";
import { audioWave, robot } from "../../assets/images";
import { toast } from "react-toastify";

const AiSpokenEnglish = () => {
  const videoRef = useRef(null);
  const [auth] = useAuth();
  const [searchValue, setSearchValue] = useState("");

  const uploadAnswer = async () => {
    toast.warning("We are working on it");
  };

  return (
    <Fragment>
      <GptLayout>
        <section className="interview_wrapper">
          <div className="container">
            <div className="interview_sec">
              <div>
                <div className="title">
                  <h2>You are preparing for speaking english with SSB-GPT</h2>
                  <p>Powered By Alpha Regiment</p>
                </div>

                <div className="attend_interview">
                  <div className="ai_img">
                    <div className="box">
                      <img src={robot} alt="ssbBot" />
                      {/* {askQuestion && ( */}
                      <div className="audio_wave">
                        <div>
                          <img src={audioWave} alt="audioWave" />
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                    {/* {preparingQuestion && <h4>Analysing...</h4>} */}
                  </div>
                  <div className="student_face_cam">
                    <div className="box">
                      <video ref={videoRef} autoPlay />
                      {/* {answerQuestion && ( */}
                      <div className="audio_wave">
                        <div>
                          <img src={audioWave} alt="audioWave" />
                        </div>
                      </div>
                      {/* )} */}
                    </div>

                    <h4>{auth?.user?.first_name}</h4>
                  </div>
                </div>
                {
                  <div className="question_list">
                    <p>
                      <span>Alpha Bot :</span>
                      <span>Tell me something about yourself</span>
                    </p>
                    <p>
                      <span>You :</span>
                      <span>
                        My self Rahul Yadav and i am a student for computer
                        science engineering.
                      </span>
                    </p>
                    
                  </div>
                }
                <div className="input_box">
                  <div>
                    <textarea
                      type="text"
                      value={searchValue}
                      style={{ border: "1px solid #fff" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      // onKeyDown={handleKeyDown}
                      placeholder="Write your answer"
                    />
                    <button className="submit" onClick={uploadAnswer}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </GptLayout>
    </Fragment>
  );
};

export default AiSpokenEnglish;
