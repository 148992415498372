import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import GptSearch from "./component/ssbGpt/gptSearch";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChapterDetail from "./component/ssbGpt/ChapterDetail";
import CustomVideoPlayer from "./component/ssbGpt/video";
import Login from "./pages/Login";
import Interview from "./component/ssbGpt/Interview";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgetPassword";
import InterviewFeedback from "./component/ssbGpt/InterviewFeedback";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SrtAttempt from "./component/ssbGpt/SrtAttempt";
import AiSpokenEnglish from "./component/ssbGpt/AiSpokenEnglish";
import PsychologyTest from "./component/ssbGpt/PsychologyTest";
// import CustomVideoPlayer from "./video";
const App = () => {
  return (
    <Fragment>
      <ToastContainer />
      <Routes>

        <Route exact path="/" element={<GptSearch />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/video" element={<CustomVideoPlayer />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/feedback" element={<InterviewFeedback />} />

        <Route path="/psychology-test-attempt" element={<PsychologyTest />} />
        <Route path="/srt-practice-test" element={<SrtAttempt />} />
        <Route path="/spoken-english" element={<AiSpokenEnglish />} />

        <Route path="/term-of-use" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/chapter-details/:id" element={<ChapterDetail />} />
        
      </Routes>
    </Fragment>
  );
};

export default App;
